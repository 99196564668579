import React from "react";
import PropTypes from "prop-types";

import AppProvider from "~context/AppContext.jsx";
import LocationProvider from "~context/LocationContext.jsx";

import "./node_modules/modern-normalize/modern-normalize.css";

const Provider = ({ element }) => (
  <>
    <AppProvider>
      <LocationProvider>{element}</LocationProvider>
    </AppProvider>
  </>
);

Provider.propTypes = {
  element: PropTypes.node.isRequired
};

export default Provider;
