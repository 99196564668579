exports.onRouteUpdate = (
  { location },
  pluginOptions = { stripQueryString: false }
) => {
  const domElem = document.querySelector(`link[rel='canonical']`);
  const existingValue = domElem?.getAttribute(`href`);
  if (existingValue) {
    const { pathname } = location;
    const { siteUrl } = pluginOptions;

    const parsedSiteUrl = siteUrl.replace(/\/$/, ``);

    // remove query strings
    let parsedPathname =
      pathname && pathname !== `/` ? pathname.split(`?`)[0] : null;
    // force trailing slash
    parsedPathname = parsedPathname
      ? `${parsedPathname}${parsedPathname.endsWith(`/`) ? `` : `/`}`
      : ``;

    const url = `${parsedSiteUrl}${parsedPathname}`;

    domElem.setAttribute(`href`, `${url}`);
  }
};
